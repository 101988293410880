import profile from "./profile.webp";
// import author1 from "./1567960418502.jpg";
// import info1 from "./1684946381386.jpg";
// import author2 from "./1621207304760.jpg";
// import info2 from "./1671700837720.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <nav className="navigation">
        <p className="hide-medium-screen">this is my business card</p>
        <p className="hide-small-screen">based in amsterdam, the netherlands</p>
        <p className="show-small-screen">Amsterdam</p>
        <a
          href="https://www.linkedin.com/in/julie-lorenzi/"
          target="_blank"
          rel="noreferrer"
        >
          linkedin
        </a>
      </nav>

      <header className="header">
        <div className="header__info">
          <p>
            Hi! I am Julie, the Marketplace Security Lead for EMEA & APAC at{" "}
            <a
              href="https://www.uber.com/nl/en/about"
              target="_blank"
              rel="noreferrer"
            >
              Uber
            </a>
          </p>
        </div>

        <div className="header__title">
          <div className="hide-medium-screen">
            <span className="header__title_1 hide-small-screen">
              <h1>Julie</h1>
              <div className="header__gradient_1" alt="Image by freepik"></div>
              <h1>Lorenzi</h1>
            </span>
            <span
              className="header__title_2 hide-small-screen"
              alt="Image by freepik"
            >
              <h2>Security</h2>
              <div className="header__gradient_2"></div>
              <h2>analyst</h2>
              <img
                className="header__title__img"
                src={profile}
                alt="Julie Lorenzi"
              />
            </span>
          </div>
          <div className="show-medium-screen">
            <img
              className="header__title__img"
              src={profile}
              alt="Julie Lorenzi"
            />
            <span className="header__title_2" alt="Image by freepik">
              <h2>Security</h2>
              <div className="header__gradient_2"></div>
              <h2>analyst</h2>
            </span>
          </div>
        </div>
      </header>

      {/* <section className="honour">
        <div className="honour__posts">
          <div className="linkedin-post post-1">
            <div className="linkedin-post-author">
              <img
                className="linkedin-post-author-img"
                src={author1}
                alt="Georgii Sokolianskyi"
              />
              <div className="linkedin-post-author-info">
                <p>
                  <b>Georgii Sokolianskyi</b>
                </p>
                <p>Head of Driver Operations, EMEA Central Ops at Uber</p>
              </div>
            </div>
            <div className="linkedin-post-info">
              <p>
                We have to keep Ukraine top of mind. Our sense of urgency cannot
                wear off as a sense of novelty does.
              </p>
              <p>
                I spoke at the ITS Congress Lisbon today about Uber's response
                to the full-scale invasion. What the team did I think is
                remarkable:
              </p>
              <li>
                Making sure that trips that matter happen: 350k free trips for
                medics, teachers and blood donors; evacuation routes to the
                Polish border; taking our cultural artefacts to safety with Uber
                Restore; aid deliveries for UNHCR & the World Food Program.
              </li>
              <li>
                Taking Uber where it was needed (18 cities across the country),
                so Riders could keep on moving and Drivers could keep on earning
                when both groups needed it the most.
              </li>
              <li>
                Continuously adjusting the service area to keep the platform
                safe.
              </li>
              <p>
                Artem Panchenko, Kateryna Netymenko, Matthew Devlin and Julie
                Lorenzi are the heroes behind the effort.
              </p>
              <img
                className="linkedin-post-img"
                src={info1}
                alt="RECAP: MENA MOBILITY TRENDS DURING THE FOOTBALL TOURNAMENT"
              />
            </div>
          </div>

          <div className="linkedin-post post-2">
            <div className="linkedin-post-author">
              <img
                className="linkedin-post-author-img"
                src={author2}
                alt="Nasser Al Sharshani"
              />
              <div className="linkedin-post-author-info">
                <p>
                  <b>Nasser Al Sharshani</b>
                </p>
                <p>GM @Uber - Qatar</p>
              </div>
            </div>
            <div className="linkedin-post-info">
              <p>
                Dare to dream, then decide to do. And we promised and did ✌🏻
              </p>
              <img
                className="linkedin-post-img"
                src={info2}
                alt="RECAP: MENA MOBILITY TRENDS DURING THE FOOTBALL TOURNAMENT"
              />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default App;
